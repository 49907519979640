import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { extractLocaleData } from '~/locale';

const transform = (input: ReturnType<typeof extractLocaleData>) => ({
  country: input.country?.toLowerCase(),
  language: input.language?.toLowerCase(),
});

export const useLocale = () => {
  const { defaultLocale, locale = defaultLocale } = useRouter();

  return useMemo(
    () => ({
      ...transform(extractLocaleData(locale)),
      locale,
    }),
    [locale],
  );
};
