import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/it';
import { Translate } from 'next-translate';

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getDurationStringUntilNow = (from: Date, t: Translate, language: string): string => {
  const now = new Date();
  const fromDate = new Date(from);
  if (now.toDateString() === fromDate?.toDateString()) {
    return t('date.today');
  }

  return moment(from).locale(language).fromNow();
};
