"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_location_1 = require("@aws-sdk/client-location");
const lib_operation_result_1 = require("@backend/lib-operation-result");
const enum_1 = require("./enum");
const mapPlace_1 = require("./utils/mapPlace");
const wrapError_1 = require("./utils/wrapError");
const validator_1 = require("./validator");
class AwsLocation {
    client;
    constructor() {
        this.client = new client_location_1.LocationClient({ region: process.env.AWS_REGION });
    }
    async searchAutoComplete(params) {
        const { text, category, maxResults, language, filterCountriesIso3Codes } = validator_1.SHAPE_AUTOCOMPLETE.parse(params);
        const command = new client_location_1.SearchPlaceIndexForSuggestionsCommand({
            FilterCategories: [enum_1.LOCATION_RESULT_TYPE_TO_AWS_LOCATION_RESULT_TYPE[category]],
            IndexName: process.env.AWS_LOCATION_INDEX_NAME,
            MaxResults: maxResults,
            Text: text.trim(),
            Language: language,
            FilterCountries: filterCountriesIso3Codes,
        });
        const { $metadata: metadata, Results } = await this.client.send(command);
        return metadata.httpStatusCode === 200
            ? (0, lib_operation_result_1.ok)({ results: Results?.map(res => ({ address: res.Text, id: res.PlaceId })) ?? [] })
            : (0, wrapError_1.wrapError)('AWS_LOCATION_AUTOCOMPLETE_ERROR', metadata);
    }
    async getPlaceDetails(params) {
        const { id, language } = validator_1.SHAPE_GET_PLACE.parse(params);
        const command = new client_location_1.GetPlaceCommand({
            PlaceId: id,
            IndexName: process.env.AWS_LOCATION_INDEX_NAME,
            Language: language,
        });
        const { $metadata: metadata, Place: place } = await this.client.send(command);
        return metadata.httpStatusCode === 200 && place
            ? (0, lib_operation_result_1.ok)({ place: (0, mapPlace_1.mapPlace)(place, id) })
            : (0, wrapError_1.wrapError)('AWS_LOCATION_PLACE_ERROR', metadata);
    }
    async reverseGeocode(params) {
        const { point, language } = validator_1.SHAPE_REVERSE_GEOCODE.parse(params);
        const command = new client_location_1.SearchPlaceIndexForPositionCommand({
            IndexName: process.env.AWS_LOCATION_INDEX_NAME,
            Position: [point.longitude, point.latitude],
            Language: language,
            MaxResults: 1,
        });
        const { $metadata: metadata, Results } = await this.client.send(command);
        return metadata.httpStatusCode === 200 && Results?.[0]?.Place
            ? (0, lib_operation_result_1.ok)({ place: (0, mapPlace_1.mapPlace)(Results[0].Place, Results[0].PlaceId ?? ''), distance: Results[0].Distance })
            : (0, wrapError_1.wrapError)('AWS_LOCATION_REVERSE_GEOCODE_ERROR', metadata);
    }
    async geocode(params) {
        const { text, category, language, filterCountriesIso3Codes } = validator_1.SHAPE_GEOCODE.parse(params);
        const command = new client_location_1.SearchPlaceIndexForTextCommand({
            FilterCategories: [enum_1.LOCATION_RESULT_TYPE_TO_AWS_LOCATION_RESULT_TYPE[category]],
            IndexName: process.env.AWS_LOCATION_INDEX_NAME,
            MaxResults: 1,
            Text: text,
            Language: language,
            FilterCountries: filterCountriesIso3Codes,
        });
        const { $metadata: metadata, Results } = await this.client.send(command);
        return metadata.httpStatusCode === 200 && Results?.[0]?.Place
            ? (0, lib_operation_result_1.ok)({ place: (0, mapPlace_1.mapPlace)(Results[0].Place, Results[0].PlaceId ?? ''), distance: Results[0].Relevance })
            : (0, wrapError_1.wrapError)('AWS_LOCATION_GEOCODE_ERROR', metadata);
    }
}
exports.default = new AwsLocation();
