import { getEnv } from '~/utils';

export function getParseURL(countryCode: string): string {
  return getEnv(`PARSE_URL_${countryCode}`);
}

export function getParseAppId(countryCode: string): string {
  return getEnv(`PARSE_APP_ID_${countryCode}`);
}

export function getParseRestApiKey(countryCode: string): string {
  return getEnv(`PARSE_REST_API_KEY_${countryCode}`);
}

export function getWebMissionApplicationUsername(countryCode: string): string {
  return getEnv(`WEB_MISSION_APPLICATION_USER_${countryCode}`);
}

export function getWebMissionApplicationPassword(countryCode: string): string {
  return getEnv(`WEB_MISSION_APPLICATION_PASSWORD_${countryCode}`);
}
