import { useCallback, useEffect, useRef } from 'react';

export const useDebouncedCallback = <T extends unknown[], U>(
  callback: (...args: T) => Promise<U>,
  delay: number,
): [(...args: T) => Promise<void>, () => boolean, () => void] => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const lastExecRef = useRef(0);
  const argsRef = useRef<T>(null);
  const timeoutRef = useRef<NodeJS.Timeout>(null);

  useEffect(
    () => () => {
      cancelNextCallback();
    },
    [],
  );

  const getNextCallbackDelay = useCallback(() => {
    if (lastExecRef.current) {
      const delta = Date.now() - lastExecRef.current;
      if (delta < delay) {
        return delay - delta;
      }
    }
    return 0;
  }, []);

  /**
   * Returns true if a callback was scheduled and has been canceled
   */
  const cancelNextCallback = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      return true;
    }
    return false;
  }, []);

  const executeCallback = useCallback(async () => {
    lastExecRef.current = Date.now();
    await callbackRef.current(...argsRef.current);
  }, []);

  const debounceCallback = useCallback(async (...args: T) => {
    argsRef.current = args;
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(async () => {
        cancelNextCallback();
        await executeCallback();
      }, getNextCallbackDelay());
    }
  }, []);

  const executeNextCallback = useCallback(() => {
    if (cancelNextCallback()) {
      return executeCallback();
    }
  }, []);

  return [debounceCallback, cancelNextCallback, executeNextCallback];
};
