import Script from 'next/script';
import React, { useMemo } from 'react';

import { getGlobalJsonld } from '~/business/App';
import { useLocale } from '~/hooks';

interface JsonLdProps {
  extraJsonLd?: Record<string, any>;
}

export const JsonLd = ({ extraJsonLd }: JsonLdProps) => {
  const { locale, country } = useLocale();

  const data = useMemo(() => getGlobalJsonld(locale, country, extraJsonLd), [locale, country, extraJsonLd]);

  return <Script id="" type="application/ld+json" dangerouslySetInnerHTML={{ __html: `${data}` }} />;
};
