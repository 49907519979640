"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapPlace = void 0;
const mapPlace = (place, id) => ({
    id,
    streetNumber: place.AddressNumber,
    streetName: place.Street,
    zipcode: place.PostalCode,
    city: place.Municipality,
    region: place.SubRegion,
    text: place.Label,
    countryIso3Code: place.Country,
    location: place.Geometry?.Point
        ? { longitude: place.Geometry.Point[0], latitude: place.Geometry.Point[1] }
        : undefined,
});
exports.mapPlace = mapPlace;
