import * as snippet from '@segment/snippet';
import Script from 'next/script';
import { FC, useMemo } from 'react';

const SegmentSnippet: FC = () => {
  const segmentSnippet = useMemo(() => snippet.min({ load: false }), []);

  return <Script id="segment-script" dangerouslySetInnerHTML={{ __html: segmentSnippet }} />;
};

export default SegmentSnippet;
