import { useCallback, useRef } from 'react';

import { useCache } from '~/hooks/useCache';

interface UseCacheFromCallbackOptions {
  debugKey?: string;
}

export const useCacheFromCallback = <V, T extends unknown[]>(
  callback: (key: string, ...args: T) => V | Promise<V>,
  { debugKey }: UseCacheFromCallbackOptions = {},
) => {
  const cache = useCache<V>();

  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const log = useCallback((message: string) => {
    if (debugKey) {
      console.debug(`useCacheFromCallback - ${debugKey} : ${message}`);
    }
  }, []);

  return useCallback(async (key: string, ...args: T): Promise<V> => {
    if (!cache[key]) {
      cache[key] = await callbackRef.current(key, ...args);
      log(`cache not hit - ${key}`);
    } else {
      log(`cache hit - ${key}`);
    }
    return cache[key];
  }, []);
};
