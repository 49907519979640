const loadLocaleFrom = locale => {
  const matches = locale.match(/(^[a-z]{2}$)|(^([a-z]{2})-([A-Z]{2})$)/);

  if (matches) {
    return import(`./src/locale/${matches[3] || matches[1]}.json`).then(m => m.default);
  }
  return import('./src/locale/dev.json').then(m => m.default);
};

const defaultLocale = 'fr';
const locales = ['fr', 'it'];

if (process.env.NODE_ENV !== 'production') locales.unshift('dev');

module.exports = {
  defaultLocale,
  locales,
  loadLocaleFrom,
  pages: {
    '*': ['all'],
  },
};

module.exports.locales = locales;
module.exports.defaultLocale = defaultLocale;
