export enum JobOfferSort {
  relevance = 'relevance',
  publication = 'publication',
}

export enum JobOfferType {
  PERMANENT = 'PERMANENT',
  CONTRACT = 'CONTRACT',
  SEASONAL_TEMPORARY = 'SEASONAL_TEMPORARY',
  NON_SEASONAL_TEMPORARY = 'NON_SEASONAL_TEMPORARY',
  TEMPORARY = 'TEMPORARY',
}

export enum JobOfferRequiredExperience {
  ANY = 'ANY',
  NONE = 'NONE',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
  TWO_YEARS = 'TWO_YEARS',
  FIVE_YEARS = 'FIVE_YEARS',
}
