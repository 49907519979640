import { getEnv } from '~/utils/env';

export function getJobsPublicURL(): string {
  return getEnv('NEXT_PUBLIC_JOBS_WEBSITE_URL', 'http://localhost:3000');
}

export function getJobsPublicApiEndpoint(country: string): string {
  return `${getJobsPublicURL()}/api/${country?.toLowerCase()}`;
}

export const getAddressApiUrl = (country: string, functionName: string): string =>
  `${getJobsPublicApiEndpoint(country)}/address/${functionName}`;
