interface LocaleData {
  language: string | undefined;
  country: string | undefined;
}

export function extractLocaleData(locale: string | undefined): LocaleData {
  const matches = locale?.match(/(^[a-z]{2}$)|(^([a-z]{2})-([A-Z]{2})$)/); // pattern to match 'xx' or 'xx-XX'

  if (matches) {
    return {
      language: matches[3] || matches[1],
      country: matches[4] || matches[1].toUpperCase(),
    };
  }
  return {
    language: undefined,
    country: undefined,
  };
}
