import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type FieldV2<T = unknown> = {
  value: T;
  defaultValue?: T;
  touched: boolean;
  touch: () => void;
  reset: () => void;
  error: string | null;
  onChange: (value: T) => void;
  onValidityChange: (data: { isValid: boolean; reason: string }) => void;
};

export const useFieldV2 = <T = unknown>(name: string, defaultValue?: T): FieldV2<T> => {
  const { t } = useTranslation('all');
  const [touched, setTouched] = useState(false);
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const touch = useCallback(() => {
    setTouched(true);
  }, []);

  const reset = useCallback(() => {
    setTouched(false);
    setError(null);
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = useCallback((val: T) => {
    setValue(val);
    touch();
  }, []);

  const onValidityChange = useCallback(
    ({ isValid, reason }) => setError(!isValid ? t(`field_errors.${name}_${reason}`) : null),
    [],
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return useMemo(
    () => ({
      value,
      error,
      reset,
      touch,
      touched,
      onChange,
      defaultValue,
      onValidityChange,
    }),
    [value, error, touch, touched, onChange, onValidityChange, reset, defaultValue],
  );
};
