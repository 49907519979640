let cachedEnv = {};

export const loadPublicEnv = (env: Record<string, string>) => {
  cachedEnv = env;
};

export const getEnv = (name: string, defaultValue?: string): string =>
  (cachedEnv[name] ?? process.env[name]) || defaultValue;

export const isProduction = () => getEnv('ENV') === 'production';
