export const getLocalStorage = (): Storage | undefined => {
  try {
    return globalThis.localStorage;
  } catch {
    // do nothing
    // user may have disabled cookies and storage
  }
};

export const getSessionStorage = (): Storage | undefined => {
  try {
    return globalThis.sessionStorage;
  } catch {
    // do nothing
    // user may have disabled cookies and storage
  }
};
