import { defaultTheme, ThemeOverrides } from '@iziwork/design-system';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

export interface CustomThemeOverrides extends ThemeOverrides {
  colors: ThemeOverrides['colors'] & { info: { normal: string } } & { secondary: { normal: string } };
  sizes: { maxBodyWidth: string };
}

export const palette = {
  darkBlue: '#272356',
  darkerBlue: '#201b3d',
  green: '#36CC75',
  darkGreen: '#3F9152',
  darkerGreen: '#28783A',
};

export const theme: CustomThemeOverrides = merge(cloneDeep(defaultTheme), {
  colors: {
    primary: {
      alpha: defaultTheme.palette.green400alpha,
      normal: palette.green,
      dark: palette.darkGreen,
      darker: palette.darkerGreen,
    },
    secondary: {
      normal: palette.darkBlue,
    },
    info: {
      normal: defaultTheme.palette.grey400,
    },
    body: {
      text: palette.darkBlue,
    },
  },
  sizes: {
    maxBodyWidth: '1150px',
  },
  designTokens: {
    overlaySpaceToTarget: '0rem',
  },
} as CustomThemeOverrides);
