"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCATION_RESULT_TYPE_TO_AWS_LOCATION_RESULT_TYPE = exports.AWS_LOCATION_RESULT_TYPE = void 0;
var AWS_LOCATION_RESULT_TYPE;
(function (AWS_LOCATION_RESULT_TYPE) {
    AWS_LOCATION_RESULT_TYPE["CITY"] = "CITY";
    AWS_LOCATION_RESULT_TYPE["ADDRESS"] = "ADDRESS";
    AWS_LOCATION_RESULT_TYPE["COUNTRY"] = "COUNTRY";
})(AWS_LOCATION_RESULT_TYPE || (exports.AWS_LOCATION_RESULT_TYPE = AWS_LOCATION_RESULT_TYPE = {}));
exports.LOCATION_RESULT_TYPE_TO_AWS_LOCATION_RESULT_TYPE = {
    [AWS_LOCATION_RESULT_TYPE.ADDRESS]: 'AddressType',
    [AWS_LOCATION_RESULT_TYPE.CITY]: 'MunicipalityType',
    [AWS_LOCATION_RESULT_TYPE.COUNTRY]: 'CountryType',
};
