import { css } from 'styled-components';

import { theme } from '~/themes/default';

export const displayFlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const displayFlexRow = css`
  display: flex;
  flex-direction: row;
`;

export const displayNone = css`
  display: none;
`;

export const tabletAndMobileOnlyMixin = css`
  @media (min-width: ${theme.devices.laptop.min}) {
    ${displayNone}
  }
`;
