import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

import { getJobsPublicApiEndpoint } from '~/config/jobs';

export const getPublicAcquisitionClient = (country: string): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    uri: `${getJobsPublicApiEndpoint(country)}/acquisition`,
    cache: new InMemoryCache(),
    headers: {
      'Accept-Language': country,
    },
  });
