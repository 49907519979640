
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ApolloProvider } from '@apollo/client';
import '@fontsource/red-hat-display/400.css';
import '@fontsource/red-hat-display/500.css';
import '@fontsource/red-hat-display/700.css';
import { ThemeProvider } from '@iziwork/design-system';
import 'intl-pluralrules';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import ConsentManager from '~/components/Analytics/ConsentManager';
import SegmentSnippet from '~/components/Analytics/SegmentSnippet';
import { JsonLd } from '~/components/JsonLd';
import { getJobsPublicURL } from '~/config';
import { TrackingContextProvider } from '~/contexts/TrackingContext';
import { useCanonicalUrl } from '~/hooks/useCanonicalUrl';
import { useLocale } from '~/hooks/useLocale';
import { theme } from '~/themes/default';
import { getPublicAcquisitionClient } from '~/utils/acquisition';
import { isProduction, loadPublicEnv } from '~/utils/env';
import { initSentry } from '~/utils/sentry';
/** to override some user-agent style, we should think of including a styled component equivalent to reset.css */
const GlobalStyle = createGlobalStyle `
  html,
  body {
    margin: 0;
    padding: 0;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
`;
interface MyAppProps extends AppProps<{
    jsonld: Record<string, any>;
}> {
    err: any;
    env: Record<string, string>;
    jsonld: Record<string, any>;
}
let isSentryInit = false;
function MyApp({ Component, pageProps, err, env }: MyAppProps) {
    loadPublicEnv(env); // this must be placed at the top to avoid missing env variables
    if (!isSentryInit) {
        isSentryInit = true;
        initSentry();
    }
    const canonicalUrl = useCanonicalUrl();
    const { country, language } = useLocale();
    const countryUpperCase = country.toUpperCase();
    const client = useMemo(() => getPublicAcquisitionClient(country), [country]);
    return (<>
      <Head>
        {!isProduction() && <meta name="robots" content="noindex"/>}
        <meta name="referrer" content="same-origin"/>

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content={`@iziwork${countryUpperCase}`}/>
        <meta name="twitter:creator" content={`@iziwork${countryUpperCase}`}/>

        <meta property="og:site_name" content="iziwork Jobs"/>
        <meta property="og:url" content={canonicalUrl}/>
        <meta property="og:site" content={`${getJobsPublicURL()}/${country}`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:locale" content={`${language}_${countryUpperCase}`}/>

        <link rel="icon" href="/images/favicon-iziwork.svg" type="image/svg+xml"/>
        <link rel="icon" href="/images/favicon.ico"/>
        <link rel="canonical" href={canonicalUrl}/>
      </Head>
      <SegmentSnippet />
      {/* Do not include `JsonLd` under `Head` tag, it is already contained in */}
      <JsonLd extraJsonLd={pageProps.jsonld}/>
      <ThemeProvider theme={theme}>
        <TrackingContextProvider>
          <ApolloProvider client={client}>
            <GlobalStyle />
            <Component {...pageProps} err={err}/>
            <ConsentManager />
          </ApolloProvider>
        </TrackingContextProvider>
      </ThemeProvider>
    </>);
}
MyApp.getInitialProps = async (context: AppContext) => {
    const ctx = await App.getInitialProps(context);
    const env = Object.fromEntries(Object.entries(process.env).filter(([key]) => key.startsWith('NEXT_PUBLIC_') || ['NODE_ENV', 'ENV'].includes(key)));
    return { ...ctx, env };
};

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  