import { ConsentManager as CM } from '@iziwork/consent-manager';
import inEU from '@segment/in-eu';
import useTranslation from 'next-translate/useTranslation';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

import { getSegmentWriteKey } from '~/config';

const ConsentBannerWrapper = styled.div`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  > div {
    > div {
      left: 15px;
      right: 15px;
      z-index: 11;
      bottom: 20px;
      margin: 0 auto;
      position: fixed;
      background: #fff;
      text-align: left;
      max-width: 1110px;
      user-select: none;
      border-radius: 8px;
      padding: 20px 20px 90px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 8px 15px 0;
      color: ${({ theme }) => theme.colors.body.text};

      .button {
        right: 20px;
        width: 100%;
        bottom: 20px;
        height: 48px;
        max-width: 86px;
        position: absolute;

        &.personnalize {
          right: 120px;
          max-width: 175px;
        }
      }

      strong {
        display: block;
        font-size: 18px;
      }

      .link {
        appearance: none;
        color: ${({ theme }) => theme.colors.body.text};
      }

      &.it {
        bottom: 40%;
      }

      @media (max-width: ${({ theme }) => theme.devices.mobile.max}) {
        padding: 32px 32px 60px;
        strong {
          font-size: 14px;
        }

        p {
          font-size: 14px;
          line-height: 22px;
        }

        .button {
          left: 0;
          right: 0;
          bottom: -35px;
          margin: 0 auto;
          position: relative;
          max-width: none !important;

          &.personnalize {
            bottom: -20px;
          }
        }
      }
    }
  }
`;

const ConsentManager: FC = () => {
  const segmentWriteKey = useMemo(getSegmentWriteKey, []);

  if (!segmentWriteKey) {
    return null;
  }

  const { t, lang } = useTranslation('all');

  return (
    <ConsentBannerWrapper>
      <CM
        lang={lang}
        closeBehavior="accept"
        writeKey={segmentWriteKey}
        shouldRequireConsent={inEU}
        preferencesDialogTitle={t('consent_manager.title')}
        preferencesDialogContent={t('consent_manager.content')}
      />
    </ConsentBannerWrapper>
  );
};

export default ConsentManager;
