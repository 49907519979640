// DTO : Data Transformer Object
import { getJobsPublicURL } from '~/config/jobs';

export const getGlobalJsonld = (locale: string, country: string, extraJsonLd?: Record<string, any>): string =>
  JSON.stringify({
    '@context': 'https://schema.org',
    '@graph': [getWebsiteJsonld(country), getCorporationJsonld(locale, country), extraJsonLd].filter(Boolean),
  });

/**
 * Generate Website JSON-LD
 * schema.org WebSite documentation : https://schema.org/WebSite
 * google json-ld format testing : https://search.google.com/test/rich-results
 */
export const getWebsiteJsonld = (country: string): Record<string, any> => ({
  '@type': 'WebSite',
  name: 'iziwork jobs',
  url: `${getJobsPublicURL()}/${country}/`,
});

/**
 * Generate Corporation JSON-LD
 * schema.org Corporation documentation : https://schema.org/Corporation
 * google json-ld format testing : https://search.google.com/test/rich-results
 */
export const getCorporationJsonld = (locale: string, country: string): Record<string, any> => {
  const links = getLinks(locale, country);

  return {
    '@type': 'Corporation',
    name: 'iziwork jobs',
    alternateName: 'iziwork',
    url: links.website,
    logo: `${getJobsPublicURL()}/images/logo-iziwork-by-proman.png`,
    sameAs: [links.twitter, links.facebook, links.instagram, links.linkedin, links.website, links.youtube],
  };
};

export const getLinks = (locale: string, country: string): ReturnType<typeof getLinks> => ({
  twitter: `https://twitter.com/iziwork${country.toUpperCase()}`,
  facebook: `https://www.facebook.com/Iziwork${country.toUpperCase()}/`,
  instagram: `https://www.instagram.com/iziwork_${country}/`,
  linkedin: 'https://www.linkedin.com/company/iziwork/',
  website: `${getJobsPublicURL()}/${locale}`,
  youtube: 'https://www.youtube.com/channel/UCJoPiK8A5c7B9QYv2obQzTQ',
  apple_store: `https://apps.apple.com/${country}/app/iziwork-offres-demploi/id1446760096?l=${locale}&ls=1`,
  google_store: `https://play.google.com/store/apps/details?id=abeka.iziwork&hl=${locale}&gl=${country.toUpperCase()}`,
});
