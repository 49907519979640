"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHAPE_GEOCODE = exports.SHAPE_REVERSE_GEOCODE = exports.SHAPE_GET_PLACE = exports.SHAPE_AUTOCOMPLETE = void 0;
const z = __importStar(require("zod"));
const enum_1 = require("./enum");
exports.SHAPE_AUTOCOMPLETE = z.object({
    category: z.nativeEnum(enum_1.AWS_LOCATION_RESULT_TYPE),
    text: z
        .string({
        required_error: 'text is required',
        invalid_type_error: 'text must be a string',
    })
        .min(1, { message: 'text must at least 1 character' }),
    maxResults: z
        .number({ message: 'maxResults must be a number' })
        .int({ message: 'maxResults must be a integer' })
        .min(1, { message: 'maxResults must at least 1' })
        .optional()
        .default(5),
    language: z.enum(['fr', 'en', 'it'], {
        required_error: 'language is required',
        invalid_type_error: 'language must fr, en or it',
    }),
    filterCountriesIso3Codes: z
        .enum(['FRA', 'ITA'], {
        required_error: 'filterCountriesIso3Codes is required',
        invalid_type_error: 'filterCountriesIso3Codes must FRA or ITA',
    })
        .array(),
});
exports.SHAPE_GET_PLACE = z.object({
    id: z
        .string({
        required_error: 'id is required',
        invalid_type_error: 'id must be a string',
    })
        .min(1, { message: 'id must at least 1 character' }),
    language: z.enum(['fr', 'en', 'it'], {
        required_error: 'language is required',
        invalid_type_error: 'language must fr, en or it',
    }),
});
exports.SHAPE_REVERSE_GEOCODE = z.object({
    point: z.object({
        longitude: z.number(),
        latitude: z.number(),
    }),
    language: z.enum(['fr', 'en', 'it'], {
        required_error: 'language is required',
        invalid_type_error: 'language must fr, en or it',
    }),
});
exports.SHAPE_GEOCODE = z.object({
    category: z.nativeEnum(enum_1.AWS_LOCATION_RESULT_TYPE),
    text: z
        .string({
        required_error: 'text is required',
        invalid_type_error: 'text must be a string',
    })
        .min(1, { message: 'text must at least 1 character' }),
    language: z.enum(['fr', 'en', 'it'], {
        required_error: 'language is required',
        invalid_type_error: 'language must fr, en or it',
    }),
    filterCountriesIso3Codes: z
        .enum(['FRA', 'ITA'], {
        required_error: 'filterCountriesIso3Codes is required',
        invalid_type_error: 'filterCountriesIso3Codes must FRA or ITA',
    })
        .array(),
});
