import { defaultParams } from '~/constants/jobOfferSearch';
import type { JobFiltersTrackedValues, TrackedFilterValue } from '~/contexts/TrackingContext';
import { Params } from '~/utils/search';

export const computeJobFiltersTrackedValues = (params: Params, totalJobOffers: number): JobFiltersTrackedValues => ({
  job: params?.job,
  city: params?.address?.name,
  distance: getTrackedValue(params, 'distance'),
  experience: getTrackedValue(params, 'experience'),
  sort: getTrackedValue(params, 'sort'),
  type: getTrackedValue(params, 'type'),
  resultsNumber: totalJobOffers,
  pageNumber: params?.page,
});

const getTrackedValue = (params: Params, property: string): TrackedFilterValue => ({
  default: params?.[property] === defaultParams[property],
  value: params?.[property],
});
