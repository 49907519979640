import { useMemo } from 'react';

import { useTrackingContext } from '~/hooks/useTrackingContext';

const PREFIX_SOURCE = 'IZIWORK_JOBS_';
const DEFAULT_SOURCE = 'ORGANIC';

export const useSource = (): string => {
  const {
    utms: { utmSource },
  } = useTrackingContext();

  return useMemo(() => `${PREFIX_SOURCE}${utmSource?.toUpperCase() || DEFAULT_SOURCE}`, [utmSource]);
};
