import useTranslation from 'next-translate/useTranslation';
import { useCallback, useState } from 'react';

interface Values<T = unknown> {
  value: T;
  error: string | null;
  touched: boolean;
}

type OnChange<T = unknown> = (value: T) => void;

type OnValidityChange = (data: { isValid: boolean; reason: string }) => void;

type OnTouch = () => void;

export type Field<T = unknown> = [Values<T>, OnChange<T>, OnValidityChange, OnTouch];

export const useField = <T = unknown>(name: string, defaultValue?: T): Field<T> => {
  const { t } = useTranslation('all');
  const [touched, setTouched] = useState(false);
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const onTouch = useCallback(() => {
    if (!touched) {
      setTouched(true);
    }
  }, []);

  const onChange = useCallback((val: T) => {
    setValue(val);
    onTouch();
  }, []);

  const onValidityChange = useCallback(
    ({ isValid, reason }) => setError(!isValid ? t(`field_errors.${name}_${reason}`) : null),
    [],
  );

  return [{ value, error, touched }, onChange, onValidityChange, onTouch];
};
