import { createContext, PropsWithChildren, useState } from 'react';

import type { DefaultFormType, FormContextPropsType, FormContextType } from '~/contexts/FormContext/types';
import { useFieldV2 as useField } from '~/hooks';

export const FormContext = createContext<FormContextType>(null);

export default function FormContextProvider<T extends DefaultFormType = DefaultFormType>({
  children,
  onSubmit,
  defaultValues,
  defaultSubmitted,
}: PropsWithChildren<FormContextPropsType<T>>) {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(defaultSubmitted ?? false);

  const form = Object.fromEntries(
    Object.entries(defaultValues).map(([field, defaultValue]) => [field, useField(field, defaultValue)]),
  );

  const getValues = () => Object.fromEntries(Object.entries(form).map(([name, field]) => [name, field.value])) as T;

  const canSubmit = () => Object.values(form).every(({ error }) => !error);

  const forceSubmit = async () => {
    setSubmitted(true);
    setSubmitting(true);
    const result = await onSubmit(getValues());
    setSubmitting(false);
    return result;
  };

  const submit = async () => {
    setSubmitted(true);
    if (canSubmit()) {
      return forceSubmit();
    }
  };

  const resetFields = (fieldsToReset?: (keyof T)[]) =>
    (fieldsToReset || Object.keys(form)).forEach(field => form[field as string]?.reset());

  const resetForm = () => {
    setSubmitted(false);
    resetFields();
  };

  return (
    <FormContext.Provider
      value={{ form, submitting, submitted, submit, forceSubmit, canSubmit, getValues, resetFields, resetForm }}
    >
      {children}
    </FormContext.Provider>
  );
}
