import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { getJobsPublicURL } from '~/config/jobs';

const transform = (path: string, locale: string) => {
  const fullpath = `${getJobsPublicURL()}/${locale}${path}`;
  return fullpath.match(/([^?]+)/)[0];
};

export const useCanonicalUrl = (): string => {
  const { asPath, locale } = useRouter();
  return useMemo(() => transform(asPath, locale), [asPath, locale]);
};
