import { JobOfferSort } from './jobOffer';

export const minDistance = 1;
export const maxDistance = 50;

export const defaultParams = {
  page: 1,
  distance: maxDistance,
  sort: JobOfferSort.relevance,
};
