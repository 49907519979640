import { DependencyList, useEffect, useState } from 'react';

/**
 * Determine if the component is mounted
 */
export const useIsMounted = (): boolean => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted;
};

/**
 * Trigger effect only if the component is mounted (do not trigger on init)
 */
export const useEffectIfMounted = (effect: () => void, deps?: DependencyList) => {
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted) {
      effect();
    }
  }, deps);
};

/**
 * Trigger async effect only if the component is mounted (do not trigger on init)
 */
export const useAsyncEffectIfMounted = (effect: () => Promise<void>, deps?: DependencyList) => {
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted) {
      effect().catch(err => console.error({ msg: 'Error running effect', err }));
    }
  }, deps);
};
