export const EVENTS = {
  missionPageLoaded: 'jobs mission page loaded',
  applyButtonClicked: 'jobs application start',
  emailSubmitted: 'jobs application email_submitted',
  applicationSubmitted: 'jobs application application_submitted',
  welcomePageLoaded: 'jobs listing page loaded',
  jobOfferClicked: 'jobs search joboffer_click',
  searchResultsDisplayed: 'jobs search results displayed',
  activeMissionsPageChanged: 'jobs search page_number',
} as const;

export type TEvents = ValueOf<typeof EVENTS>;
